<template>
  <!-- <keep-alive :include="keepAlive"> -->
  <router-view />
  <!-- </keep-alive> -->
</template>

<script>
import db from "@/utils/localStorage";
import { mapState } from "vuex";
import themeColor from "@/assets/styles/themeColor";
import { getHashSearchParam } from "@/utils/fn";

export default {
  name: "globalControl",
  created() {
    this.setToken()
    this.drakCompile();
    this.getBackUrl();
    this.getLanguageType()
    this.changeDocumentTitle();

  },
  watch: {
    theme: {
      immediate: true, // 首次加载的时候执行函数
      handler() {
        this.themeHandle();
      },
    },
    // "user.isLogin": {
    //   immediate: true, // 首次加载的时候执行函数
    //   handler(isLogin) {
    //     if (isLogin) {
    //     //   this.$store.dispatch("user/getBal");
    //     }
    //   },
    // },
  },
  computed: {
    theme() {
      return this.common.theme || "light";
    },
    ...mapState(["common", "user"]),
  },
  methods: {
    changeDocumentTitle() {
      document.title = this.$t('documentTitle')
    },
    getLanguageType() {
      const languageType = getHashSearchParam('languageType') || db.get("languageType", "")
      if (languageType) {
        db.save('languageType', languageType)
        this.$store.commit("common/SET_DATA", {name: 'languageType' ,data: languageType});
        this.$i18n.locale = languageType == 'CMN'? 'zh' : 'en'
        if(languageType == "VIE"){
          this.$i18n.locale = 'vn'
        }
      }
    },
    getBackUrl() {
      const backUrl =
        encodeURI(getHashSearchParam("url")) || db.get("backUrl", "");
      if (backUrl) {
        db.save("backUrl", backUrl);
        this.$store.commit("common/SET_DATA", {
          name: "backUrl",
          data: backUrl,
        });
      }
    },
    setToken() {
      const _token = getHashSearchParam('token') || db.get("token", "")
      const _nickname = getHashSearchParam('nickname') || db.get("nickname", "")
      if (_token) {
        this.$store.commit("common/SET_TOKEN", _token);
      }
      if (_nickname) {
        this.$store.commit("common/SET_NICKNAME", _nickname);
      }
    },
    drakCompile() {
      const theme = getHashSearchParam('theme') || db.get("theme", "")
      if (theme) {
        this.$store.commit("common/SET_THEME", theme);
      }
    },
    themeHandle(){
      if(this.theme && themeColor[this.theme]){
        let themeColorObj = themeColor[this.theme];
        Object.keys(themeColorObj).forEach(key => {
          document.getElementsByTagName('body')[0].style.setProperty(key, themeColorObj[key])
        })
      }
    }
  },
};
</script>

<style>
</style>