export default {
    dark: {
        '--primary': '#268026',
        '--success': '#FDA702',
        '--text-color': 'rgba(255, 255, 255, 0.7)',
        '--text-color-light': '#fff',
        '--bg-color': '#202020',
        '--bg-color-light': '#242424',
    },
    light: {
        '--primary': '#268026',
        '--success': '#FDA702',
        '--text-color': '#131A3E',
        '--text-color-light': '#202020',
        '--bg-color': '#F4F4F4',
        '--bg-color-light': '#F2F0DB',
    }
}